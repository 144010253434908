import { render, staticRenderFns } from "./dns.vue?vue&type=template&id=35d4d776&scoped=true"
import script from "./dns.vue?vue&type=script&lang=js"
export * from "./dns.vue?vue&type=script&lang=js"
import style0 from "./dns.vue?vue&type=style&index=0&id=35d4d776&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35d4d776",
  null
  
)

export default component.exports
<template>
  <div>
    <div>
      <div ref="globeDiv" class="globe-container"></div>
    </div>
    <!-- <div v-else>
      <div
        class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
      >
        <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
        <h3 class="font-weight-bolder">No Data Found</h3>
      </div>
    </div> -->
  </div>
</template>

<script>
import * as THREE from "three";
import ThreeGlobe from "three-globe";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      globe: null,
      scene: null,
      camera: null,
      renderer: null,
      controls: null,
    };
  },
  mounted() {
    this.initGlobe();
    this.markLocations(this.items);
    window.addEventListener("resize", this.onWindowResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  methods: {
    initGlobe() {
      // Create scene
      this.scene = new THREE.Scene();

      // Add lighting
      const ambientLight = new THREE.AmbientLight(0xffffff);
      this.scene.add(ambientLight);
      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.6);
      directionalLight.position.set(1, 1, 1).normalize();
      this.scene.add(directionalLight);

      // Create camera
      this.camera = new THREE.PerspectiveCamera(
        75,
        this.$refs.globeDiv.clientWidth / this.$refs.globeDiv.clientHeight,
        0.1,
        1000
      );
      this.camera.position.z = 200;

      // Create renderer
      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      this.renderer.setSize(
        this.$refs.globeDiv.clientWidth,
        this.$refs.globeDiv.clientHeight
      );
      this.$refs.globeDiv.appendChild(this.renderer.domElement);

      // Ensure canvas takes full width and height of the container
      this.renderer.domElement.style.width = "100%";
      this.renderer.domElement.style.height = "100%";

      // Create controls
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.enableZoom = true;
      this.controls.enableRotate = true;

      // Handle window resize
      window.addEventListener("resize", this.onWindowResize);

      // Create globe
      this.globe = new ThreeGlobe()
        .globeImageUrl(
          "//unpkg.com/three-globe/example/img/earth-blue-marble.jpg"
        )
        .bumpImageUrl("//unpkg.com/three-globe/example/img/earth-topology.png");
      this.scene.add(this.globe);

      // Animation loop
      const animate = () => {
        requestAnimationFrame(animate);
        this.controls.update();
        this.renderer.render(this.scene, this.camera);
      };
      animate();
    },
    markLocations(locations) {
      // Process the locations and add them to the globe
      const processedLocations = locations.map((item) => {
        const lat = parseFloat(item.lat);
        const lng = parseFloat(item.lon);

        // Add check to log invalid values
        if (isNaN(lat) || isNaN(lng)) {
          console.error("Invalid lat/lng values:", item);
        }

        return {
          lat,
          lng,
          color: "white", // You can set different colors based on your requirement
          radius: 0.5,
          altitude: 0.1, // Adjust altitude to position the marker above the globe
        };
      });

      console.log("Processed Locations:", processedLocations); // Debugging line

      // Add arcs (markers) to the globe
      this.globe
        .arcsData(processedLocations)
        .arcColor("color")
        .arcAltitude("altitude")
        .arcStroke("radius");
    },
    onWindowResize() {
      this.camera.aspect =
        this.$refs.globeDiv.clientWidth / this.$refs.globeDiv.clientHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(
        this.$refs.globeDiv.clientWidth,
        this.$refs.globeDiv.clientHeight
      );
    },
  },
};
</script>

<style scoped>
.globe-container {
  width: 100%;
  height: 100vh;
  position: relative;
}
</style>

<template>
  <div>
    <b-card-code>
      <div>
        <b-table
          id="emailTable"
          :fields="fields"
          :items="formattedItems"
          :per-page="perPage"
          v-if="items"
          hover
          responsive
          style="min-height: 200px"
        >
          <template #cell(index)="data">
            {{ data.index + 1 + perPage * (currentPage - 1) }}
          </template>
        </b-table>
        <div v-else>
          <div
            class="w-100 d-flex flex-column align-items-center justify-content-center py-5"
          >
            <feather-icon icon="MailIcon" size="34" class="mb-50" />
            <h3 class="font-weight-bolder">No Emails Discoverd</h3>
          </div>
        </div>
      </div>
    </b-card-code>
  </div>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";

import {
  BModal,
  BPagination,
  BPaginationNav,
  BButton,
  BRow,
  BCol,
  BBadge,
  BCard,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BForm,
  BFormTextarea,
  BSpinner,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardHeader,
    BCardBody,
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BFormSelect,
    BSpinner,
  },
  data() {
    return {
      pagination_pos: "center",
      // Call orgaization API and return in this format
      items: [],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      fields: [
        { key: "index", label: "#" },
        { key: "email", label: "Email" },
      ],
    };
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    formattedItems() {
      if (this.items) {
        return this.items.map((email, index) => ({ index: index + 1, email }));
      }
    },
  },
  watch: {},
  created: function () {},
  methods: {},
};
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"no-body":""}},[_c('div',[(_vm.items.length)?_c('b-table',{attrs:{"responsive":"","id":"blTable","fields":_vm.fields,"items":_vm.items},scopedSlots:_vm._u([{key:"cell(asset)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.fields.name)+" "),_c('br'),(
            data.item.fields.is_newly_founded &&
            data.item.fields.domain != null
          )?_c('div',[_c('button',{staticClass:"btn btn-outline-warning btn-sm mr-1",staticStyle:{"position":"relative"},attrs:{"type":"button","disabled":_vm.isDisable},on:{"click":function($event){return _vm.addToAssets(data.item.fields.id, 'domain', data.index)}}},[(_vm.isLoading && data.index === _vm.currentIndexDomain)?_c('span',[_c('b-spinner',{attrs:{"type":"border","small":""}}),_vm._v("Please wait")],1):_c('span',[_vm._v("Add to assets")])]),_c('button',{staticClass:"btn btn-outline-warning btn-sm mr-1",attrs:{"type":"button","disabled":_vm.isDisable},on:{"click":function($event){return _vm.addAndScan(data.item.fields.id, 'domain')}}},[(_vm.isLoading && data.index === _vm.currentIndexDomain)?_c('span',[_c('b-spinner',{attrs:{"type":"border","small":""}}),_vm._v("Please wait")],1):_c('span',[_vm._v(" Add and Scan")])])]):(
            !data.item.fields.domain_added_to_asset &&
            data.item.fields.domain != null
          )?_c('div',[_c('button',{staticClass:"btn btn-outline-primary btn-sm mr-1",staticStyle:{"position":"relative"},attrs:{"type":"button","disabled":_vm.isDisable},on:{"click":function($event){return _vm.addToAssets(data.item.fields.id, 'domain', data.index)}}},[(_vm.isLoading && data.index === _vm.currentIndexDomain)?_c('span',[_c('b-spinner',{attrs:{"type":"border","small":""}}),_vm._v("Please wait")],1):_c('span',[_vm._v("Add to assets")])]),_c('button',{staticClass:"btn btn-outline-primary btn-sm mr-1",attrs:{"type":"button","disabled":_vm.isDisable},on:{"click":function($event){return _vm.addAndScan(data.item.fields.id, 'domain')}}},[(_vm.isLoading && data.index === _vm.currentIndexDomain)?_c('span',[_c('b-spinner',{attrs:{"type":"border","small":""}}),_vm._v("Please wait")],1):_c('span',[_vm._v(" Add and Scan")])])]):(
            data.item.fields.domain_added_to_asset &&
            data.item.fields.domain != null
          )?_c('div',[_c('button',{staticClass:"btn btn-outline-success btn-sm mr-1",staticStyle:{"position":"relative","padding-left":"35px","padding-right":"35px"},attrs:{"type":"button","disabled":!data.item.fields.domain_ref_asset_id},on:{"click":function($event){return _vm.$router.push({
                path: '/assets/scan/' + data.item.fields.domain_ref_asset_id,
              })}}},[_vm._v(" Added ")])]):_vm._e()]}},{key:"cell(ip_address)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.fields.ip_address)+" "),_c('br'),(
            data.item.fields.is_newly_founded &&
            data.item.fields.ip_address != null
          )?_c('div',[_c('button',{staticClass:"btn btn-outline-warning btn-sm mr-1",staticStyle:{"position":"relative"},attrs:{"type":"button","disabled":_vm.isDisableIP},on:{"click":function($event){return _vm.addToAssets(data.item.fields.id, 'ip', data.index)}}},[(_vm.isLoadingIP && data.index === _vm.currentIndexIP)?_c('span',[_c('b-spinner',{attrs:{"type":"border","small":""}}),_vm._v("Please wait")],1):_c('span',[_vm._v("Add to assets")])]),_c('button',{staticClass:"btn btn-outline-warning btn-sm mr-1",attrs:{"type":"button","disabled":_vm.isDisableIP},on:{"click":function($event){return _vm.addAndScan(data.item.fields.id, 'ip')}}},[(_vm.isDisableIP && data.index === _vm.currentIndexIP)?_c('span',[_c('b-spinner',{attrs:{"type":"border","small":""}}),_vm._v("Please wait")],1):_c('span',[_vm._v(" Add and Scan")])])]):(
            !data.item.fields.ip_added_to_asset &&
            data.item.fields.ip_address != null
          )?_c('div',[_c('button',{staticClass:"btn btn-outline-primary btn-sm mr-1",staticStyle:{"position":"relative"},attrs:{"type":"button","disabled":_vm.isDisableIP},on:{"click":function($event){return _vm.addToAssets(data.item.fields.id, 'ip', data.index)}}},[(_vm.isLoadingIP && data.index === _vm.currentIndexIP)?_c('span',[_c('b-spinner',{attrs:{"type":"border","small":""}}),_vm._v("Please wait")],1):_c('span',[_vm._v("Add to assets")])]),_c('button',{staticClass:"btn btn-outline-primary btn-sm mr-1",attrs:{"type":"button","disabled":_vm.isDisableIP},on:{"click":function($event){return _vm.addAndScan(data.item.fields.id, 'ip')}}},[(_vm.isDisableIP && data.index === _vm.currentIndexIP)?_c('span',[_c('b-spinner',{attrs:{"type":"border","small":""}}),_vm._v("Please wait")],1):_c('span',[_vm._v(" Add and Scan")])])]):(
            data.item.fields.ip_added_to_asset &&
            data.item.fields.ip_address != null
          )?_c('div',[_c('button',{staticClass:"btn btn-outline-success btn-sm mr-1",staticStyle:{"position":"relative","padding-left":"35px","padding-right":"35px"},attrs:{"type":"button","disabled":!data.item.fields.ip_ref_asset_id},on:{"click":function($event){return _vm.$router.push({
                path: '/assets/scan/' + data.item.fields.ip_ref_asset_id,
              })}}},[_vm._v(" Added ")])]):_vm._e()]}}],null,false,2129387598)}):_c('div',{},[_c('div',{staticClass:"w-100 d-flex flex-column align-items-center justify-content-center py-2"},[_c('feather-icon',{staticClass:"mb-50",attrs:{"icon":"DatabaseIcon","size":"34"}}),_c('h3',{staticClass:"font-weight-bolder"},[_vm._v("No Data Found")])],1)]),_c('div',{staticClass:"mt-1"},[_c('b-pagination-nav',{attrs:{"numberOfPages":_vm.total,"total-rows":_vm.rows,"link-gen":_vm.linkGen,"per-page":_vm.perPage,"align":_vm.pagination_pos,"aria-controls":"blTable","use-router":""},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="col-12 my-1">
    <h4 class="mb-1">Progress</h4>
    <!-- <b-progress :max="max">
      <b-progress-bar variant="success" :value="values[0]" show-progress />
      <b-progress-bar variant="warning" :value="values[1]" show-progress />
      <b-progress-bar variant="primary" :value="values[2]" show-progress />
      <b-progress-bar variant="success" :value="values[3]" show-progress />
    </b-progress> -->
    <b-progress :max="max">
      <b-progress-bar variant="#7367f0" :value="values[0]" :max="max">
        <span>{{ values[0] }}</span>
      </b-progress-bar>
      <b-progress-bar variant="warning" :value="values[1]" :max="max">
        <span>{{ values[1] }}</span>
      </b-progress-bar>
      <b-progress-bar variant="success" :value="values[2]" :max="max">
        <span>{{ values[2] }}</span>
      </b-progress-bar>
      <b-progress-bar variant="danger" :value="values[3]" :max="max">
        <span>{{ values[3] }}</span>
      </b-progress-bar>
    </b-progress>
    <div class="d-flex justify-content-start mt-1">
      <div class="d-flex">
        <span class="d-flex align-items-center p-1px text-dark"
          ><div class="box critical mb-0 m-1px"></div>
          Scheduled</span
        >
        <span class="d-flex align-items-center p-1px text-dark"
          ><div class="box medium mb-0 m-1px"></div>
          Inprogress</span
        >
        <span class="d-flex align-items-center p-1px text-dark"
          ><div class="box low medium mb-0 m-1px"></div>
          Success</span
        >
        <span class="d-flex align-items-center p-1px text-dark"
          ><div class="box high  mb-0 m-1px"></div>
          Failed</span
        >
      </div>
    </div>
  </div>
</template>
<script>
import {
  BCard,
  BTabs,
  BTab,
  BRow,
  BCol,
  BBadge,
  BProgressBar,
  BProgress,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    BRow,
    BCol,
    BBadge,
    BProgressBar,
    BProgress,
  },
  props: {
    values: {
      type: Array,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      //   values: [3, 5, 1, 0 ],
      //   max: 4,
    };
  },
  created: function () {},
  mounted() {},
  methods: {},
};
</script>
<style scoped>
span {
  display: block;
  width: 100%;
  text-align: center;
  color: white;
}

.box {
  float: left;
  height: 10px;
  width: 20px;
  margin-bottom: 15px;
  border: 1px solid rgb(255, 255, 255);
  clear: both;
}
.critical {
  /* background-color: #a020f0; */
  background-color: #7367f0;
}
.high {
  background-color: #ea5455;
}
.medium {
  background-color: #ff9f43;
}
.low {
  background-color: #28c76f;
}
.p-1px {
  padding-left: 2px;
  padding-right: 2px;
}
.m-1px {
  margin-left: 2px;
  margin-right: 2px;
}
</style>


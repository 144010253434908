<template>
  <div>
    <div v-if="items.length">
      <table>
        <thead>
          <tr>
            <th>Domain Name</th>
            <th>First seen</th>
            <th class="text-center">Still exists</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="domain in items" :key="domain">
            <td>{{ domain.domain }}</td>
            <td>{{ domain.firstSeen | formatdate }}</td>
            <td>
              <div class="d-flex justify-content-center">
                <span v-if="domain.stillExists === true">
                  <feather-icon size="24" icon="CheckIcon" />
                </span>
                <span v-else>
                  <feather-icon size="24" icon="XIcon" />
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <div
        class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
      >
        <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
        <h3 class="font-weight-bolder">No Data Found</h3>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BBadge,
  BButton,
  BAvatar,
  VBTooltip,
} from "bootstrap-vue";
import moment from "moment";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    BButton,
    BAvatar,
  },
  data() {
    return {
      items: {},
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YYYY h:mA");
      }
    },
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  created: function () {},
  mounted() {},
  methods: {},
};
</script>
<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ebe9f1;
}

th {
  text-align: left;
  padding: 8px;
  background-color: #f3f2f7;
}
[dir] .dark-layout th {
  background-color: #343d55;
}

[dir] .dark-layout td {
  border-bottom: 1px solid #3b4253;
}
.bg-purple {
  background: rgb(160 32 240 / 22%) !important;
}
.text-purple {
  color: #a020f0 !important;
}
[dir] .dark-layout .text-purple {
  color: #d084ff !important;
}
.border-purple {
  border: 1px solid #a020f0 !important;
}
</style>
<template>
  <div>
    <div v-if="!isEmptyObject(items)">
      <div v-for="(records, type) in items" :key="type" class="my-4">
        <div v-if="records.length">
          <h2>{{ type }}</h2>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>TTL</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="record in records"
                :key="
                  record.address ||
                  record.exchange ||
                  record.target ||
                  record.text
                "
              >
                <td>{{ record.name }}</td>
                <td>{{ record.ttl }}</td>
                <td>
                  <span v-if="record.address">{{ record.address }}</span>
                  <span v-if="record.exchange">{{ record.exchange }}</span>
                  <span v-if="record.target">{{ record.target }}</span>
                  <span v-if="record.text">{{ record.text }}</span>
                  <span v-if="record.mname">{{ record.mname }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-else>
      <div
        class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
      >
        <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
        <h3 class="font-weight-bolder">No Data Found</h3>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BBadge,
  BButton,
  BAvatar,
  VBTooltip,
} from "bootstrap-vue";
import moment from "moment";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    BButton,
    BAvatar,
  },
  data() {
    return {
      items: [],
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YYYY");
      }
    },
  },
  props: {
    items: {
      type: Object,
      required: true,
    },
  },
  created: function () {},
  mounted() {},
  methods: {
    isEmptyObject(obj) {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    },
  },
};
</script>
<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ebe9f1;
}

th {
  text-align: left;
  padding: 8px;
  background-color: #f3f2f7;
}
[dir] .dark-layout th {
  background-color: #343d55;
}

[dir] .dark-layout td {
  border-bottom: 1px solid #3b4253;
}
.bg-purple {
  background: rgb(160 32 240 / 22%) !important;
}
.text-purple {
  color: #a020f0 !important;
}
[dir] .dark-layout .text-purple {
  color: #d084ff !important;
}
.border-purple {
  border: 1px solid #a020f0 !important;
}
</style>
<template>
  <b-card>
    <div class="d-flex justify-content-end">
      <b-button @click="goBack()" size="sm" variant="primary">Back</b-button>
    </div>
    <b-tabs v-model="tabIndex">
      <b-tab title="Overview">
        <div class="row">
          <ProgressBar :values="progress_values" :max="progressitems.total" />
          <div class="col-12" v-if="items.web_application_overview">
            <h4 class="my-1">Web Application Summary</h4>
            <div>
              <b-row>
                <b-col class="2">
                  <b-card
                    class="card bg-primary border-white rounded cursor-pointer"
                    @click="
                      $router.push({
                        path: '/assets/vulnerabilities',
                        query: {
                          master_scan_id: masterScanId,
                          tabIndex: 1,
                        },
                      })
                    "
                  >
                    <span class="text-white">Total Vulns</span>
                    <h6 class="text-white">
                      {{ items.web_application_overview.total_vulns }}
                    </h6>
                  </b-card>
                </b-col>
                <b-col class="2">
                  <b-card
                    class="card bg-purple border-purple rounded cursor-pointer"
                    @click="
                      $router.push({
                        path: '/assets/vulnerabilities',
                        query: {
                          severity: 'critical',
                          master_scan_id: masterScanId,
                          tabIndex: 1,
                        },
                      })
                    "
                  >
                    <span class="text-purple">Critical Vulns</span>
                    <h6 class="text-purple">
                      {{ items.web_application_overview.critical_vulns }}
                    </h6>
                  </b-card>
                </b-col>
                <b-col class="2">
                  <b-card
                    class="card bg-light-danger border-danger rounded cursor-pointer"
                    @click="
                      $router.push({
                        path: '/assets/vulnerabilities',
                        query: {
                          severity: 'high',
                          master_scan_id: masterScanId,
                          tabIndex: 1,
                        },
                      })
                    "
                  >
                    <span>High Vulns</span>
                    <h6 class="text-danger">
                      {{ items.web_application_overview.high_vulns }}
                    </h6>
                  </b-card>
                </b-col>
                <b-col class="2">
                  <b-card
                    class="card bg-light-warning border-warning rounded cursor-pointer"
                    @click="
                      $router.push({
                        path: '/assets/vulnerabilities',
                        query: {
                          severity: 'medium',
                          master_scan_id: masterScanId,
                          tabIndex: 1,
                        },
                      })
                    "
                  >
                    <span>Medium Vulns</span>
                    <h6 class="text-warning">
                      {{ items.web_application_overview.medium_vulns }}
                    </h6>
                  </b-card>
                </b-col>
                <b-col class="2">
                  <b-card
                    class="card bg-light-success border-success rounded cursor-pointer"
                    @click="
                      $router.push({
                        path: '/assets/vulnerabilities',
                        query: {
                          severity: 'low',
                          master_scan_id: masterScanId,
                          tabIndex: 1,
                        },
                      })
                    "
                  >
                    <span>Low Vulns</span>
                    <h6 class="text-success">
                      {{ items.web_application_overview.low_vulns }}
                    </h6>
                  </b-card>
                </b-col>
                <b-col class="2">
                  <b-card
                    class="card bg-light-info border-info rounded cursor-pointer"
                    @click="
                      $router.push({
                        path: '/assets/vulnerabilities',
                        query: {
                          severity: 'info',
                          master_scan_id: masterScanId,
                          tabIndex: 1,
                        },
                      })
                    "
                  >
                    <span>Info Vulns</span>
                    <h6 class="text-info">
                      {{ items.web_application_overview.info_vulns }}
                    </h6>
                  </b-card>
                </b-col>
              </b-row>
            </div>
          </div>
          <div class="col-12" v-if="items.nmap_result.overview">
            <h4 class="my-1">Port Scan</h4>
            <b-row class="align-items-center">
              <b-col class="col-4">
                <!-- <b-card class="card"> -->
                <HostGraph :values="hostValues" />
                <!-- </b-card> -->
              </b-col>
              <b-col class="col-4" v-if="items.nmap_result.overview">
                <div class="row">
                  <div class="col-12">
                    <b-card class="card w-100" style="border: 1px solid">
                      <table style="width: 100%">
                        <tr>
                          <th>Port</th>
                          <th>Vulnerability</th>
                        </tr>
                        <tr
                          v-for="(vulns, port) in items.nmap_result.overview
                            .port_vulns"
                          :key="port"
                        >
                          <td>{{ port }}</td>

                          <td style="white-space: nowrap">
                            <span
                              class="align-items-center"
                              v-for="(key, value) in vulns"
                              :key="key"
                            >
                              <b-avatar
                                v-if="value === 'critical'"
                                style="background-color: #a020f0 !important"
                                class="text-capitalize"
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                v-b-tooltip.hover.top
                                :title="
                                  value.charAt(0).toUpperCase() + value.slice(1)
                                "
                                button
                              >
                                {{ key }}
                              </b-avatar>
                              <b-avatar
                                v-if="value === 'high'"
                                variant="danger"
                                class="text-capitalize"
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                v-b-tooltip.hover.top
                                :title="
                                  value.charAt(0).toUpperCase() + value.slice(1)
                                "
                              >
                                {{ key }}
                              </b-avatar>
                              <b-avatar
                                v-if="value === 'medium'"
                                variant="warning"
                                class="text-capitalize"
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                v-b-tooltip.hover.top
                                :title="
                                  value.charAt(0).toUpperCase() + value.slice(1)
                                "
                              >
                                {{ key }}
                              </b-avatar>
                              <b-avatar
                                v-if="value === 'low'"
                                variant="success"
                                class="text-capitalize"
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                v-b-tooltip.hover.top
                                :title="
                                  value.charAt(0).toUpperCase() + value.slice(1)
                                "
                              >
                                {{ key }}
                              </b-avatar>
                              <b-avatar
                                v-if="value === 'info'"
                                style="background-color: #7367f0 !important"
                                class="text-capitalize"
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                v-b-tooltip.hover.top
                                :title="
                                  value.charAt(0).toUpperCase() + value.slice(1)
                                "
                              >
                                {{ key }}
                              </b-avatar>
                            </span>
                          </td>
                        </tr>
                      </table>
                    </b-card>
                  </div>
                </div>
              </b-col>
              <b-col class="col-4" v-if="items.nmap_result.overview">
                <h5 class="m-1">Tech Used</h5>
                <b-card class="card">
                  <span v-if="items.nmap_result.overview.tech_used.country"
                    >Country:
                    {{ items.nmap_result.overview.tech_used.country[0] }}</span
                  ><br />
                  <span v-if="items.nmap_result.overview.tech_used.http_server"
                    >HTTP Server:
                    {{
                      items.nmap_result.overview.tech_used.http_server[0]
                    }}</span
                  ><br />
                  <span v-if="items.nmap_result.overview.tech_used.ip"
                    >IP: {{ items.nmap_result.overview.tech_used.ip[0] }}</span
                  ><br />
                  <span v-if="items.nmap_result.overview.tech_used.nginx"
                    >NGINX:
                    {{ items.nmap_result.overview.tech_used.nginx[0] }}</span
                  ><br />
                </b-card>
              </b-col>
            </b-row>
          </div>
          <div class="col-12" v-if="items.ssl_result.overview">
            <h4 class="my-1">SSL Certificate Expiry</h4>
            <b-card class="card">
              <span class="align-middle">Certificate Expiry: </span>
              <b-badge
                :variant="
                  getExpiryBadgeVariant(items.ssl_result.overview.cert_expiry)
                "
                class="cursor-pointer"
                @click="
                  $router.push({
                    path: `/assets/scan/${items.asset_id}`,
                  })
                "
              >
                {{ items.ssl_result.overview.cert_expiry | formatdate }}
              </b-badge>
            </b-card>
          </div>
          <div class="col-5" v-if="items.ipreputation_result.overview">
            <h4 class="my-1">IP Reputation</h4>
            <div class="row">
              <div class="col-12">
                <b-card class="card w-100" style="border: 1px solid">
                  <table style="width: 100%">
                    <tr>
                      <th>Host</th>
                      <th>Fraud Score</th>
                    </tr>
                    <tr>
                      <td>
                        <div class="cursor-pointer" @click="tabIndex = 1">
                          {{ items.ipreputation_result.overview.host }}
                        </div>
                      </td>
                      <td>
                        <b-badge variant="primary">{{
                          items.ipreputation_result.overview.fraud_score
                        }}</b-badge>
                      </td>
                    </tr>
                  </table>
                </b-card>
              </div>
            </div>
          </div>

          <div class="col-3" v-if="items.public_emails.overview">
            <h4 class="my-1">Public Emails</h4>
            <div class="row align-items-center">
              <b-col>
                <b-card style="border: 1px solid">
                  <div>
                    <h6 class="text-dark">
                      <span class="text-dark">Total Email : </span>
                      <b-badge
                        variant="primary"
                        class="cursor-pointer"
                        @click="switchToDetailTab(7)"
                        >{{
                          items.public_emails.overview.total_emails
                        }}</b-badge
                      >
                    </h6>
                    <br />
                    <h6 class="text-dark">
                      <span class="text-dark">Breached Email : </span>
                      <b-badge
                        variant="primary"
                        class="cursor-pointer"
                        @click="
                          $router.push({
                            path: '/Discovery',
                            query: { tabIndex: 2, breached: 1 },
                          })
                        "
                        >{{
                          items.public_emails.overview.breached_emails
                        }}</b-badge
                      >
                    </h6>
                    <br />
                    <h6 class="text-dark">
                      <span class="text-dark">Total Domain Discoverd : </span>
                      <b-badge
                        variant="primary"
                        class="cursor-pointer"
                        @click="switchToDetailTab(6)"
                        >{{ items.amass_result.overview.total_count }}</b-badge
                      >
                    </h6>
                  </div>
                </b-card>
              </b-col>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab title="Detail Findings">
        <b-tabs v-model="detailIndex">
          <b-tab title="IP Reputation">
            <IpReputation
              :items="items.ipreputation_result"
              :asmSummary="true"
            />
          </b-tab>
          <b-tab title="Port Scan">
            <AttackSurfaceSummary
              :items="items.nmap_result"
              :asmSummary="true"
            />
          </b-tab>
          <b-tab title="Email Security Scan">
            <Dsscan :items="items.dss_result" :asmSummary="true" />
          </b-tab>
          <b-tab title="Web Application Scan">
            <!-- zap -->
            <ZapVulnerabilitySummary
              :items="items.zap_result_data"
              :asmSummary="true"
              :vulnItems="zapVulnItems"
              :totalRows="zaptotalRows"
            />
          </b-tab>
          <b-tab title="Network Scan">
            <NessusVulnerabilitySummary
              :items="items.nessus_result"
              :asmSummary="true"
              :vulnItems="nessusVulnItems"
              :totalRows="totalRows"
            />
          </b-tab>
          <b-tab title="SSL Scan ">
            <SSLLabSummary :items="items.ssl_result" :asmSummary="true" />
          </b-tab>
          <b-tab title="Domain Discovery ">
            <!-- amass scan -->
            <AmassScanSummary :items="domainItems" />
          </b-tab>
          <b-tab title="Public Emails ">
            <!-- email discovery scan -->
            <PublicEmailSummary :items="items.public_emails.scan_result" />
          </b-tab>
        </b-tabs>
      </b-tab>
      <b-tab title="Domain Info">
        <DomainInfo :assetId="this.$route.query.asset_id" :tabIndex="tabIndex"/>
      </b-tab>
        <b-tab title="Scan List">
        <ScanList  :tabIndex="tabIndex"  :items="items.scan_list"/>
      </b-tab>
    </b-tabs>
  </b-card>
</template>
<script>
import {
  BCard,
  BTabs,
  BTab,
  BRow,
  BCol,
  BBadge,
  BProgressBar,
  BProgress,
  BButton,
  BAvatar,
  VBTooltip,
} from "bootstrap-vue";
import moment from "moment";
import Ripple from "vue-ripple-directive";
// import VulnerabilitySummary from "../../Scan/components/vulnerabilitySummary.vue";
// import BaselineSummary from "./baseLineSummary.vue";
import IpReputation from "../../Scan/components/ipReputationSummary.vue";
import SSLLabSummary from "../../Scan/components/sslSummary.vue";
import AttackSurfaceSummary from "../../Scan/components/attackSurfaceSummary.vue";
import Dsscan from "../../Scan/components/dsscanSummary.vue";
import ProgressBar from "./progressBar.vue";
import NessusVulnerabilitySummary from "./nessusZapSummaryResult.vue";
import ZapVulnerabilitySummary from "./nessusZapSummaryResult.vue";
import AmassScanSummary from "./amassSummary.vue";
import PublicEmailSummary from "./publicEmailsSummary.vue";
import HostGraph from "./hostGraph.vue";
import DomainInfo from "./domainInfo/index.vue";
import ScanList from "./scanList.vue"
// import HostGraph from "../../Dashboard/AttackSurfaceGraph/hostGraph.vue";
export default {
  components: {
    BCard,
    BTabs,
    BTab,
    BRow,
    BCol,
    BBadge,
    BProgressBar,
    BProgress,
    BButton,
    // VulnerabilitySummary,
    // BaselineSummary,
    IpReputation,
    SSLLabSummary,
    AttackSurfaceSummary,
    Dsscan,
    ProgressBar,
    NessusVulnerabilitySummary,
    ZapVulnerabilitySummary,
    AmassScanSummary,
    PublicEmailSummary,
    HostGraph,
    BAvatar,
    DomainInfo,
    ScanList
  },
  data() {
    return {
      items: [],
      vulnItems: [],
      ports: [],
      asmSummary: true,
      values: [15, 50, 10],
      max: 100,
      progressitems: {},
      progress_values: [],
      nessusVulnItems: [],
      domainItems: [],
      publicEmailItems: [],
      zapVulnItems: [],
      zaptotalRows: "",
      totalRows: "",
      hostValues: [],
      tabIndex: 0,
      masterScanId: this.$route.params.id,
      detailIndex: 0,
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YYYY");
      }
    },
  },
  created: function () {
    this.getProgressBar();
    this.getSummary();
  },
  mounted() {
    this.onVerticalCollapse();
  },
  methods: {
    onVerticalCollapse() {
      let coll = true;
      this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", coll);
    },
    getProgressBar: function () {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "scan/master-scan-progress?master_scan_id=" +
          this.$route.params.id,
      };
      var self = this;
      this.$http(options).then((res) => {
        this.progressitems = res.data;
        this.progress_values = [
          self.progressitems.scheduled,
          self.progressitems.inprogress,
          self.progressitems.completed,
          self.progressitems.failed,
        ];
      });
    },
    getSummary: function () {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "scan/master-scan-summary?master_scan_id=" +
          this.$route.params.id,
      };
      var self = this;
      this.$http(options).then((res) => {
        this.items = res.data;
        this.zapVulnItems = JSON.parse(this.items.zap_result_data.vulns);
        this.nessusVulnItems = JSON.parse(this.items.nessus_result.vulns);
        this.totalRows = JSON.parse(this.items.nessus_result.vulns).length;
        this.domainItems = JSON.parse(this.items.amass_result.scan_result);
        this.zaptotalRows = JSON.parse(this.items.zap_result_data.vulns).length;
        this.hostValues = [
          parseInt(self.items.nmap_result.overview.uphosts, 10),
          parseInt(self.items.nmap_result.overview.downhosts, 10),
        ];
      });
    },
    getExpiryBadgeVariant(expiryDate) {
      if (expiryDate) {
        const currentDate = moment();
        const expiryDateMoment = moment(expiryDate);
        if (currentDate < expiryDateMoment) {
          return "success"; // Show in success variant
        } else {
          return "danger"; // Show in danger variant
        }
      }
      return ""; // Return empty string if expiry date is not available
    },
    goBack() {
      this.$router.push("/attack-surface");
      // this.$router.go(-1);
    },
    switchToDetailTab(detailTabIndex) {
      this.tabIndex = 1; // Switch to the second main tab
      this.$nextTick(() => {
        this.detailIndex = detailTabIndex; // Switch to the specified detail tab
      });
    },
  },
};
</script>
<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ebe9f1;
}

th {
  text-align: left;
  padding: 8px;
  background-color: #f3f2f7;
}
[dir] .dark-layout th {
  background-color: #343d55;
}

[dir] .dark-layout td {
  border-bottom: 1px solid #3b4253;
}
.bg-purple {
  background: rgb(160 32 240 / 22%) !important;
}
.text-purple {
  color: #a020f0 !important;
}
[dir] .dark-layout .text-purple {
  color: #d084ff !important;
}
.border-purple {
  border: 1px solid #a020f0 !important;
}
</style>
<template>
  <div>
    <div class="row align-items-center mt-1" v-if="vulnItems.length">
      <b-col class="col-12">
        <b-row>
          <b-col class="2">
            <b-card class="card bg-purple border-purple rounded">
              <span>Critical Vulns</span>
              <h6 class="text-purple">
                {{ items.scan_details.critical_vulns }}
              </h6>
            </b-card>
          </b-col>
          <b-col class="2">
            <b-card class="card bg-light-danger border-danger rounded">
              <span>High Vulns</span>
              <h6 class="text-danger">
                {{ items.scan_details.high_vulns }}
              </h6>
            </b-card>
          </b-col>
          <b-col class="2">
            <b-card class="card bg-light-warning border-warning rounded">
              <span>Medium Vulns</span>
              <h6 class="text-warning">
                {{ items.scan_details.medium_vulns }}
              </h6>
            </b-card>
          </b-col>
          <b-col class="2">
            <b-card class="card bg-light-success border-success rounded">
              <span>Low Vulns</span>
              <h6 class="text-success">
                {{ items.scan_details.low_vulns }}
              </h6>
            </b-card>
          </b-col>
          <b-col class="2">
            <b-card class="card bg-light-info border-info rounded">
              <span>Info Vulns</span>
              <h6 class="text-info">
                {{ items.scan_details.info_vulns }}
              </h6>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </div>
    <div class="row">
      <b-col class="col-12" v-if="vulnItems.length">
        <b-table
          responsive
          id="vulnTable"
          :fields="fields"
          :items="vulnItems"
          :per-page="perPage"
          :current-page="currentPage"
          hover
        >
          <template #cell(index)="data">
            {{ data.index + 1 + perPage * (currentPage - 1) }}
          </template>
          <template #cell(is_verified)="data">
            <b-avatar v-if="data.item.is_verified" variant="success">
              <feather-icon icon="ThumbsUpIcon" />
            </b-avatar>
            <b-avatar v-else variant="danger">
              <feather-icon icon="ThumbsDownIcon" />
            </b-avatar>
          </template>
          <template v-slot:cell(severity)="data">
            <b-badge
              style="background-color: #991d28"
              v-if="data.item.severity == 'critical'"
              >Critical</b-badge
            >
            <b-badge variant="danger" v-else-if="data.item.severity == 'high'"
              >High</b-badge
            >
            <b-badge
              variant="warning"
              v-else-if="data.item.severity == 'medium'"
              >Medium</b-badge
            >
            <b-badge variant="primary" v-else-if="data.item.severity == 'low'"
              >Low</b-badge
            >
            <b-badge variant="success" v-else-if="data.item.severity == 'info'"
              >Info</b-badge
            >
            <b-badge variant="secondary" v-else>Unknown</b-badge>
          </template>
          <template v-slot:cell(status)="data">
            <b-badge variant="danger" v-if="data.item.status == 0"
              >Open</b-badge
            >
            <b-badge variant="success" v-if="data.item.status == 1"
              >Closed</b-badge
            >
            <b-badge variant="warning" v-if="data.item.status == 2"
              >False Positive</b-badge
            >
            <b-badge variant="success" v-if="data.item.status == 3"
              >Accepted</b-badge
            >
            <b-badge variant="warning" v-if="data.item.status == 4"
              >Assigned</b-badge
            >
            <b-badge variant="warning" v-if="data.item.status == 5"
              >Mitigated</b-badge
            >
          </template>
          <template v-slot:cell(is_assigned)="data">
            <b-badge variant="success" v-if="data.item.is_assigned == true"
              >Yes</b-badge
            >
            <b-badge variant="danger" v-else>No</b-badge>
          </template>
        </b-table>

        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
      <b-col v-else class="">
        <div
          class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
        >
          <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
          <h3 class="font-weight-bolder">No Data Found</h3>
        </div>
      </b-col>
    </div>
  </div>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BBadge,
  BTable,
  BAvatar,
  BPaginationNav,
  BPagination,
} from "bootstrap-vue";
import moment from "moment";
export default {
  components: {
    BCardCode,
    BCard,
    BRow,
    BButton,
    BCol,
    BBadge,
    BTable,
    BAvatar,
    BPaginationNav,
    BPagination,
  },
  data() {
    return {
      items: [],
      vulnItems: [],
      perPage: 100,
      totalRows: 0,
      currentPage: 1,
      fields: [
        { key: "index", label: "#" },
        // { key: "", label: "select" },
        { key: "name", label: "Name", sortable: true },
        { key: "port", label: "Port" },
        { key: "created_at", label: "Added At" },
        { key: "is_verified", label: "Verified" },
        { key: "severity", label: "Severity" },
        { key: "status", label: "Status" },
        { key: "is_assigned", label: "Assigned" },
      ],
    };
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    vulnItems: {
      type: Array,
      required: true,
    },
    totalRows: {
      type: Number,
      required: true,
    },
    asmSummary: {
      type: Boolean,
      required: true,
    },
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
      }
    },
  },
  // created: function () {
  //   this.getSummary();
  // },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.bg-purple {
  background: rgb(160 32 240 / 22%) !important;
}
.text-purple {
  color: #a020f0 !important;
}
.border-purple {
  border: 1px solid #a020f0 !important;
}
</style>

<template>
  <div>
    <b-tabs v-model="domainIndex">
      <b-tab title="DNS">
        <DNS :items="dnsItems" />
      </b-tab>
      <!-- <b-tab title="DNS Map"> <Map :items="dnsMapItems" /> </b-tab> -->
      <b-tab title="Whois">
        <Whois :items="textLines" />
      </b-tab>
      <b-tab title="Certs">
        <Certs :items="certItems" />
      </b-tab>
      <b-tab title="Subdomains"> <SubDomain :items="subDomainItems" /> </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import {
  BCard,
  BTabs,
  BTab,
  BRow,
  BCol,
  BBadge,
  BProgressBar,
  BProgress,
  BButton,
  BAvatar,
  VBTooltip,
} from "bootstrap-vue";
import moment from "moment";
import Ripple from "vue-ripple-directive";
import DNS from "./dns.vue";
import Whois from "./whois.vue";
import Certs from "./cert.vue";
import SubDomain from "./subDomain.vue";
import Map from "./map.vue";
export default {
  components: {
    BCard,
    BTabs,
    BTab,
    BRow,
    BCol,
    BBadge,
    BProgressBar,
    BProgress,
    BButton,
    BAvatar,
    DNS,
    Whois,
    Certs,
    SubDomain,
    Map,
  },
  data() {
    return {
      items: {},
      dnsItems: {},
      dnsMapItems: [],
      dMap: [
        { lat: 37.7809, lon: -122.4245, color: "red" },
        { lat: 34.0522, lon: -118.2437, color: "blue" },
        // More locations...
      ],
      detailIndex: 0,
      whoisItems: {},
      certItems: [],
      subDomainItems: [],
      tabIndex: 0,
      domainIndex: 0,
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YYYY");
      }
    },
  },
  props: {
    assetId: {
      type: Number,
      required: true,
    },
    tabIndex: {
      type: Number,
      required: true,
    },
  },
  watch: {
    tabIndex: function (newVal, oldVal) {
      if (newVal === 2) {
        // this.domainIndex = 0;
        this.getDomain();
        // this.$router.replace({ query: null });
      }
    },
  },
  created: function () {
    if (this.tabIndex === 2) {
      //   this.domainIndex = 0;
      this.getDomain();
    }
  },
  mounted() {
    this.onVerticalCollapse();
  },
  methods: {
    onVerticalCollapse() {
      let coll = true;
      this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", coll);
    },
    getDomain: function () {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/domain-digger?asset_id=" +
          this.assetId,
      };
      var self = this;
      this.$http(options).then((res) => {
        this.items = res.data.results[0];
        this.dnsItems = this.items.dns_record;
        this.dnsMapItems = [this.items.geo_locations];
        this.textLines = this.splitTextToLines(this.items.whois_info.text);
        this.certItems = this.items.certificates;
        this.subDomainItems = this.items.subdomains;
      });
    },
    splitTextToLines(text) {
      return text.split("\n");
    },
  },
};
</script>
<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ebe9f1;
}

th {
  text-align: left;
  padding: 8px;
  background-color: #f3f2f7;
}
[dir] .dark-layout th {
  background-color: #343d55;
}

[dir] .dark-layout td {
  border-bottom: 1px solid #3b4253;
}
.bg-purple {
  background: rgb(160 32 240 / 22%) !important;
}
.text-purple {
  color: #a020f0 !important;
}
[dir] .dark-layout .text-purple {
  color: #d084ff !important;
}
.border-purple {
  border: 1px solid #a020f0 !important;
}
</style>
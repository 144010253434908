<template>
  <b-card-code no-body>
    <div>
      <b-table
        v-if="items.length"
        responsive
        id="blTable"
        :fields="fields"
        :items="items"
      >
        <template #cell(asset)="data">
          {{ data.item.fields.name }} <br />
          <div
            v-if="
              data.item.fields.is_newly_founded &&
              data.item.fields.domain != null
            "
          >
            <button
              @click="addToAssets(data.item.fields.id, 'domain', data.index)"
              type="button"
              class="btn btn-outline-warning btn-sm mr-1"
              style="position: relative"
              :disabled="isDisable"
            >
              <span v-if="isLoading && data.index === currentIndexDomain"
                ><b-spinner type="border" small />Please wait</span
              >
              <span v-else>Add to assets</span>
            </button>
            <button
              type="button"
              class="btn btn-outline-warning btn-sm mr-1"
              @click="addAndScan(data.item.fields.id, 'domain')"
              :disabled="isDisable"
            >
              <span v-if="isLoading && data.index === currentIndexDomain"
                ><b-spinner type="border" small />Please wait</span
              >
              <span v-else> Add and Scan</span>
            </button>
          </div>
          <div
            v-else-if="
              !data.item.fields.domain_added_to_asset &&
              data.item.fields.domain != null
            "
          >
            <button
              @click="addToAssets(data.item.fields.id, 'domain', data.index)"
              type="button"
              class="btn btn-outline-primary btn-sm mr-1"
              style="position: relative"
              :disabled="isDisable"
            >
              <span v-if="isLoading && data.index === currentIndexDomain"
                ><b-spinner type="border" small />Please wait</span
              >
              <span v-else>Add to assets</span>
            </button>
            <button
              type="button"
              class="btn btn-outline-primary btn-sm mr-1"
              @click="addAndScan(data.item.fields.id, 'domain')"
              :disabled="isDisable"
            >
              <span v-if="isLoading && data.index === currentIndexDomain"
                ><b-spinner type="border" small />Please wait</span
              >
              <span v-else> Add and Scan</span>
            </button>
          </div>
          <div
            v-else-if="
              data.item.fields.domain_added_to_asset &&
              data.item.fields.domain != null
            "
          >
            <button
              type="button"
              class="btn btn-outline-success btn-sm mr-1"
              style="
                position: relative;
                padding-left: 35px;
                padding-right: 35px;
              "
              :disabled="!data.item.fields.domain_ref_asset_id"
              @click="
                $router.push({
                  path: '/assets/scan/' + data.item.fields.domain_ref_asset_id,
                })
              "
            >
              Added
            </button>
          </div>
        </template>
        <template #cell(ip_address)="data">
          {{ data.item.fields.ip_address }} <br />
          <div
            v-if="
              data.item.fields.is_newly_founded &&
              data.item.fields.ip_address != null
            "
          >
            <button
              @click="addToAssets(data.item.fields.id, 'ip', data.index)"
              type="button"
              class="btn btn-outline-warning btn-sm mr-1"
              style="position: relative"
              :disabled="isDisableIP"
            >
              <span v-if="isLoadingIP && data.index === currentIndexIP"
                ><b-spinner type="border" small />Please wait</span
              >
              <span v-else>Add to assets</span>
            </button>
            <button
              type="button"
              class="btn btn-outline-warning btn-sm mr-1"
              @click="addAndScan(data.item.fields.id, 'ip')"
              :disabled="isDisableIP"
            >
              <span v-if="isDisableIP && data.index === currentIndexIP"
                ><b-spinner type="border" small />Please wait</span
              >
              <span v-else> Add and Scan</span>
            </button>
          </div>
          <div
            v-else-if="
              !data.item.fields.ip_added_to_asset &&
              data.item.fields.ip_address != null
            "
          >
            <button
              @click="addToAssets(data.item.fields.id, 'ip', data.index)"
              type="button"
              class="btn btn-outline-primary btn-sm mr-1"
              style="position: relative"
              :disabled="isDisableIP"
            >
              <span v-if="isLoadingIP && data.index === currentIndexIP"
                ><b-spinner type="border" small />Please wait</span
              >
              <span v-else>Add to assets</span>
            </button>
            <button
              type="button"
              class="btn btn-outline-primary btn-sm mr-1"
              @click="addAndScan(data.item.fields.id, 'ip')"
              :disabled="isDisableIP"
            >
              <span v-if="isDisableIP && data.index === currentIndexIP"
                ><b-spinner type="border" small />Please wait</span
              >
              <span v-else> Add and Scan</span>
            </button>
          </div>
          <div
            v-else-if="
              data.item.fields.ip_added_to_asset &&
              data.item.fields.ip_address != null
            "
          >
            <button
              type="button"
              class="btn btn-outline-success btn-sm mr-1"
              style="
                position: relative;
                padding-left: 35px;
                padding-right: 35px;
              "
              :disabled="!data.item.fields.ip_ref_asset_id"
              @click="
                $router.push({
                  path: '/assets/scan/' + data.item.fields.ip_ref_asset_id,
                })
              "
            >
              Added
            </button>
          </div>
        </template>
      </b-table>
      <div v-else class="">
        <div
          class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
        >
          <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
          <h3 class="font-weight-bolder">No Data Found</h3>
        </div>
      </div>
      <div class="mt-1">
        <b-pagination-nav
          v-model="currentPage"
          :numberOfPages="total"
          :total-rows="rows"
          :link-gen="linkGen"
          :per-page="perPage"
          :align="pagination_pos"
          aria-controls="blTable"
          use-router
        />
      </div>
    </div>
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BRow,
  BPaginationNav,
  BSpinner,
  BFormInput,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BCardCode,
    BTable,
    BRow,
    BPaginationNav,
    BSpinner,
    BFormInput,
  },
  props: {
    items: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { key: "fields.domain", label: "Domain" },
        { key: "asset", label: "Domain Discovered" },
        { key: "ip_address", label: "IP" },
      ],
      items: [],
      // organization: !this.$store.state.app.user.is_staff
      //   ? this.$store.state.app.user.profile.organization
      //   : this.$store.state.app.org_id,
      organization: this.$store.state.app.org_id,
      pagination_pos: "center",
      currentPage: 1,
      perPage: 10,
      rows: 0,
      total: 1,
      isLoading: false,
      isDisable: false,
      isLoadingIP: false,
      isDisableIP: false,
      type: "",
      currentIndexDomain: Number,
      currentIndexIP: Number,
      domain_filter: "",
      ip_filter: "",
    };
  },
  watch: {},
  created: function () {},
  methods: {
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },
    load: function (reset = true) {
      if (this.$route.query.page) this.currentPage = this.$route.query.page;
      if (reset) this.currentPage = 1;
      let url =
        process.env.VUE_APP_BASEURL +
        "dashboard/v1/domains-discovered?query=true" +
        "&page=" +
        this.currentPage +
        "&org_id=" +
        this.organization;

      if (this.domain_filter != "") {
        url = url + "&domain=" + this.domain_filter;
      }
      if (this.ip_filter != "") {
        url = url + "&ip_address=" + this.ip_filter;
      }
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        console.log(res.data.results);
        // this.toastMessage(res)  // method call to method2
        this.items = res.data.results;
        this.rows = res.data.results.length;
        this.total = Math.ceil(res.data.count / this.perPage);
      });
    },
    resetFn() {
      this.domain_filter = "";
      this.ip_filter = "";
      this.load();
    },
    addToAssets: function (id, type, index) {
      this.type = type;
      this.currentIndexDomain = index;
      this.currentIndexIP = index;
      if (this.type === "domain") {
        this.isDisable = true;
        this.isLoading = true;
      } else {
        this.isDisableIP = true;
        this.isLoadingIP = true;
      }
      let data = {
        type: type,
      };
      // add-to-asset
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset-discovery/discovered-assets/" +
          id +
          "/add-to-asset/",
      };
      var self = this;
      this.$http(options).then((res) => {
        if (res.data) {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "TrashIcon",
              variant: "success",
            },
          });
          this.load();
        } else {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "TrashIcon",
              variant: "danger",
            },
          });
        }
        this.isDisable = false;
        this.isLoading = false;
        this.isDisableIP = false;
        this.isLoadingIP = false;
      });
    },
    addAndScan: function (id, type, index) {
      this.type = type;
      this.currentIndexDomain = index;
      this.currentIndexIP = index;
      if (this.type === "domain") {
        this.isDisable = true;
        this.isLoading = true;
      } else {
        this.isDisableIP = true;
        this.isLoadingIP = true;
      }
      let data = {
        type: type,
      };
      // add-to-asset
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset-discovery/discovered-assets/" +
          id +
          "/add-asset-and-scan/",
      };
      var self = this;
      this.$http(options).then((res) => {
        if (res.data) {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "TrashIcon",
              variant: "success",
            },
          });
          this.loadAssets();
        } else {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "TrashIcon",
              variant: "danger",
            },
          });
        }
        this.isDisable = false;
        this.isLoading = false;
        this.isDisableIP = false;
        this.isLoadingIP = false;
      });
    },
  },
};
</script>
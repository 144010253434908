<template>
  <div>
    <div v-if="items && items.length">
      <table>
        <thead>
          <tr>
            <th class="equal-width">Logged At</th>
            <th class="equal-width">Not Before</th>
            <th class="equal-width">Not After</th>
            <th class="equal-width">Common Name</th>
            <th class="equal-width text-center">Matching Identities</th>
            <th class="equal-width text-center">Issuer Name</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="cert in processedCertificates" :key="cert.id">
            <td>{{ cert.entry_timestamp | formatdate }}</td>
            <td>{{ cert.not_before | formatdate }}</td>
            <td>{{ cert.not_after | formatdate }}</td>
            <td>{{ cert.common_name }}</td>
            <td class="text-center" v-html="cert.name_value"></td>
            <td class="text-center">{{ cert.issuer_name }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <div
        class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
      >
        <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
        <h3 class="font-weight-bolder">No Data Found</h3>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BBadge, BButton, BAvatar, VBTooltip } from "bootstrap-vue";
import moment from "moment";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    BButton,
    BAvatar,
  },
  data() {
    return {
      certificates: [],
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YYYY h:mmA");
      }
    },
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  created() {
    // Assign the passed 'items' prop to the 'certificates' data property
    this.certificates = this.items;
  },
  computed: {
    processedCertificates() {
      return this.items.map((cert) => {
        return {
          ...cert,
          name_value: cert.name_value.split("\n").join("<br>"),
        };
      });
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ebe9f1;
}

th {
  background-color: #f3f2f7;
  text-align: left;
}

[dir] .dark-layout th {
  background-color: #343d55;
}

[dir] .dark-layout td {
  border-bottom: 1px solid #3b4253;
}

.bg-purple {
  background: rgb(160 32 240 / 22%) !important;
}

.text-purple {
  color: #a020f0 !important;
}

[dir] .dark-layout .text-purple {
  color: #d084ff !important;
}

.border-purple {
  border: 1px solid #a020f0 !important;
}

.equal-width {
  width: 16.66%; /* Adjust as needed */
}
</style>
